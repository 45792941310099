import React from "react";
import "./index.css";
import BamstarterNameLogo from "../../images/new_imgs/BamstarterNameLogo.svg";
import BlockSafu from "../../images/BlockSafu.png";
import Telegram from "../../images/new_imgs/Social/telegram.svg";
import Twitter from "../../images/new_imgs/Social/twitter.svg";
import Medium from "../../images/new_imgs/Social/medium.svg";
import Gitbook from "../../images/new_imgs/Social/gitbook.svg";
import Github from "../../images/new_imgs/Social/github.svg";
import Email from "../../images/new_imgs/Social/email.svg";
import Coingecko from "../../images/new_imgs/Social/coinGecko.svg";
import Coinmarketcap from "../../images/new_imgs/Social/coinMarketCap.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer container-fluid d-flex justify-content-center justify-content-lg-start">
        <div className="row w-100">
          <div className="col-1 d-none d-lg-block" />
          <div className={`${window.innerWidth > 991 ? "col-11" : "col-12"}`}>
            <div className="py-4 flex-column flex-lg-row px-0 container-lg d-flex justify-content-between gap-3 align-items-start align-items-lg-center">
              <div className="d-flex flex-row flex-lg-column justify-content-between justify-content-lg-center align-items-center align-items-lg-start col-12 col-lg-5 gap-2">
                <a target="_blank" rel="noreferrer" href="https://btcbam.com/">
                  <img
                    src={BamstarterNameLogo}
                    alt="bams"
                    width={`${window.innerWidth > 768 ? "130px" : "100px"}`}
                  />
                </a>
              </div>
              <hr
                className="form-divider my-2 d-flex d-lg-none"
                style={{ height: 2 }}
              />
              <div className="social-and-links d-flex align-items-end flex-column-reverse flex-lg-column justify-content-center gap-4">
                <div className="social-profile">
                  <span className="mobile-footer-title d-flex d-lg-none mb-3">
                    Community
                  </span>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/BtcbamG"
                      >
                        <img
                          src={Twitter}
                          alt="Twitter"
                          height={24}
                          width={24}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://t.me/bitcoinbamofficial"
                      >
                        <img
                          src={Telegram}
                          alt="Telegram"
                          height={24}
                          width={24}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://t.me/bitcoinbamofficial"
                      >
                        <img
                          src={Gitbook}
                          alt="Telegram"
                          height={24}
                          width="30px"
                        />
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://btcbamofficial.medium.com/"
                      >
                        <img src={Medium} alt="Medium" height={24} width={24} />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:contact@btcbam.com"
                      >
                        <img src={Email} alt="Mail" height={24} width={24} />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.coingecko.com/tr/coins/bitcoinbam"
                      >
                        <img
                          src={Coingecko}
                          alt="Coingecko"
                          height={24}
                          width={24}
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://coinmarketcap.com/currencies/bitcoin-bam/"
                      >
                        <img
                          src={Coinmarketcap}
                          alt="Coinmarketcap"
                          height={24}
                          width={24}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                <hr
                  className="form-divider my-2 d-flex d-lg-none w-100"
                  style={{ height: 2 }}
                />
                <div className="footer-menu">
                  <span className="mobile-footer-title d-flex d-lg-none mb-3">
                    Links
                  </span>
                  <ul className="external-links">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://bscscan.com/address/0xcf0990170A60DA34fFCffa14eAD4a3De27D0F4CE#code"
                      >
                        Token Contract
                      </a>
                    </li>
                    <li>
                      <Link to="/Disclaimer">Disclaimer</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 d-none d-lg-block" />
        </div>
      </div>
    </>
  );
};

export default Footer;
