export const BamsToken = "0xcf0990170A60DA34fFCffa14eAD4a3De27D0F4CE"; //btcbam
export const token = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"; // busd token mainnet
export const address = ""; // seedsale mainnet
export const privateAddress = ""; // privatesale mainnet
export const airdrop = ""; //bamstarter airdrop mainet
export const vesting = ""; //bamstarter seedvesting mainnet
export const publicAddress = ""; //bamstarter PublisSale mainnet
export const privateVesting = ""; //bamstarter vesitng private mainnet
export const biswapLp = ""; //bamstarter bams-usdt farm mainnet
export const biswapFarm = ""; //bamstarter farm mainnet
export const BamsPool24 = "0xeb1852a6A5B8575E4D844b6499C7cA0071B26992"; //Bamstarter pool mainnet
export const BamsPool18 = ""; //Bamstarter pool mainnet
export const BamsPoolS24 = ""; //Bamstarter pool mainnet
export const BamsPoolP24 = ""; //Bamstarter pool mainnet
export const BamsPoolA124 = ""; //Bamstarter pool mainnet
export const BamsPoolA224 = ""; //Bamstarter pool mainnet
export const BamsPoolA324 = ""; //Bamstarter pool mainnet
export const BamsPoolA325 = ""; //Bamstarter pool mainnet
export const BamsPoolA326 = ""; //Bamstarter pool mainnet