import {ethers} from 'ethers'

const contract = async (provider,tokenAddress, tokenABI) => {
    let signer = provider.getSigner();
    let contract = new ethers.Contract(tokenAddress, tokenABI, signer);
    return contract;
  };

  export const approve = async(provider,tokenAddress,tokenAbi,spenderAddress)=>{
    let cont = await contract(provider,tokenAddress,tokenAbi)
    let value = 5000
    return await cont.approve(spenderAddress,ethers.utils.parseUnits(value.toString(), 8))
}

export const checkApprove = async (provider,tokenAddress,tokenAbi,userAddress,spenderAddress) => {
    let cont = await contract(provider,tokenAddress,tokenAbi);
    let result = await cont.allowance(userAddress,spenderAddress);
	console.log(result); 
    return result;
  };

  export const balance = async(provider,tokenAddress,tokenAbi,userAddress) =>{
    let cont = await contract(provider,tokenAddress,tokenAbi);
    let result = await cont.balanceOf(userAddress)
    return  parseFloat(ethers.utils.formatUnits(result, 8)).toFixed(3)
}