import BTCBAM from "../images/BTCBAM.svg";
import {
  BamsPool24
} from "../address";
import {
  BamsPool24ABI

} from "../abi";

export const data = [
  {
    token: "Staking Pool",
    apr: "24.00%",
    tvl: "-",
    img: BTCBAM,
    pool: BamsPool24,
    poolAbi: BamsPool24ABI,
    contract:
      "https://bscscan.com/address/0xeb1852a6A5B8575E4D844b6499C7cA0071B26992#code",
    month: "18",
    limit: "100000",
    showAuditLog: false,
    depositOn: true,
    address: "0xeb1852a6A5B8575E4D844b6499C7cA0071B26992",
  }
];
