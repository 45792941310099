import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Launchpad/Launchpad.css";
import "./Public.css";
import coinLogo from "../../images/coinlogo.svg";
import { approve, checkApprove, getAccountBalance } from "../../contracts/busd";
import {
  getDeposit,
  claimInitialToken,
  claimToken,
  checkDeposit,
  checkBalance,
  checkAmount,
} from "../../contracts/public";
// import modal from "../../modal";
import { publicAddress } from "../../address";
import Countdown from "react-countdown";
import website from "../../images/website.png";
import twitterb from "../../images/twitterb.png";
import teleb from "../../images/teleb.png";
import mediumb from "../../images/mediumb.png";
import { useContext } from "react";
import { ConnectContext } from "../../context/ConnectContext";

const Public = () => {
  const [status, setStatus] = useState(true);
  const [value, setValue] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [provider] = useContext(ConnectContext);

  const handleCheckApprove = async () => {
    // let provider = await modal();
    const accounts = await provider.listAccounts();
    if (accounts) {
      let value = await checkApprove(accounts[0], publicAddress, provider);
      console.log(value.toString());
      if (parseInt(value.toString()) > 0) setStatus(true);
      else setStatus(false);
      return status;
    }
  };

  const handleApprove = async () => {
    let bool = await handleCheckApprove();
    console.log(bool);
    if (!bool) {
      let res = await approve(publicAddress, provider);
      let confirmation = res.wait();
      if (confirmation.blockNumber) setStatus(true);
    }
  };
  const handleDeposit = async () => {
    let bool = await handleCheckApprove();
    // console.log(bool);
    if (bool && value >= 10) {
      // if(handleCheckApprove()){
      getDeposit(value, provider);
    }
  };

  const handleInitialClaim = async () => {
    // let provider = await modal();
    const accounts = await provider.listAccounts();
    if (accounts) {
      let result = await claimInitialToken(accounts[0], provider);
      console.log(result);
    }
  };
  const handleClaim = async () => {
    // let provider = await modal();
    const accounts = await provider.listAccounts();
    if (accounts) {
      claimToken(accounts[0], provider);
    }
  };

  const handlecheckDeposit = async () => {
    // let provider = await modal();
    // const accounts = await provider.listAccounts();
    //  if(accounts) {
    let sum = await checkDeposit(provider);
    setDeposit(sum);
    //  }
  };

  const handleBalance = async () => {
    // let provider = await modal();
    const accounts = await provider.listAccounts();
    if (accounts) {
      let sum = await checkBalance(accounts[0], provider);
      setBalance(sum);
    }
  };

  const handleAmount = async () => {
    // let provider = await modal();
    const accounts = await provider.listAccounts();
    if (accounts) {
      let sum = await checkAmount(accounts[0], provider);
      setAmount(sum);
    }
  };

  const handleAccountBalance = async () => {
    // let provider = await modal();
    const accounts = await provider.listAccounts();
    if (accounts) {
      let sum = await getAccountBalance(accounts[0], provider);
      setValue(sum);
    }
  };

  useEffect(() => {
    // handleCheckApprove();
    handlecheckDeposit();
    handleBalance();
    handleAmount();
  });

  let innerWidth = ((500000 / 500000) * 100).toString();
  console.log(innerWidth);
  let date = new Date(1653919200000); // live date
  return (
    <>
      <div className="el-main">
        <div className="ido-content">
          <div className="ido-detail">
            <div className="back">
              <div className="back-container">
                <Link
                  to="/Launchpad"
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  <span>&#32;&#60;&#32;Back</span>
                </Link>
              </div>
            </div>
            <div className="ido-container">
              <div className="ido-container-left">
                <div className="item1">
                  <div className="item-top">
                    <div className="idologo">
                      <img src={coinLogo} alt="logo" className="ido-logo" />
                    </div>
                  </div>
                  <div className="ido-desc line4hidden">
                    bastarter.com is a DeFi's, Cross-Chain Liquidity enabler
                    network that allows its users to earn compound interest on
                    their crypto holdings. Through a set of investment
                    strategies secured and enforced by smart contracts, BamStarter
                    network automatically maximizes the user rewards from
                    various liquidity pools (LPs),‌ ‌automated market making
                    (AMM) projects,‌ ‌and‌ ‌other yield‌ farming ‌opportunities
                    in the DeFi ecosystem.
                  </div>
                </div>
                <div className="ido-links">
                  <div className="ido-link-item">
                    <a
                      href="https://btcbam.com/"
                      className="href"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={website} alt="" />
                    </a>
                  </div>
                  <div className="ido-link-item">
                    <a
                      href="https://twitter.com/BtcbamG"
                      className="href"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={twitterb} alt="" />
                    </a>
                  </div>
                  <div className="ido-link-item">
                    <a
                      href="https://t.me/bitcoinbamofficial"
                      className="href"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={teleb} alt="" />
                    </a>
                  </div>
                  <div className="ido-link-item">
                    <a
                      href="https://btcbamofficial.medium.com/"
                      className="href"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={mediumb} alt="" />
                    </a>
                  </div>
                </div>
                <div className="cap">
                  <div className="soft-cap item-column">
                    <div className="cap-title column-title"> Soft Cap</div>
                    <div className="cap-value column-value"> 0 BTCBAM </div>
                  </div>
                  <div className="hart-cap item-column">
                    <div className="cap-title column-title"> Hard Cap</div>
                    <div className="cap-value column-value"> 666,667 BTCBAM </div>
                  </div>
                </div>
                <div className="supported-coin item-column">
                  <div className="coin-title column-title">
                    {" "}
                    Supported Coin{" "}
                  </div>
                  <div className="theme_color column-value"> BUSD </div>
                  <div className="exchange-rate column-value">
                    <span> 1 BTCBAM = 0.75 BUSD / 1 BUSD = 1.33 BTCBAM</span>
                  </div>
                </div>

                <div className="supported-coin item-column">
                  <div className="coin-title column-title"> Token Address </div>
                  {/* <div className="unit"> BUSD </div> */}
                  <div className="exchange-rate column-value">
                    <span className="theme_color">
                      {" "}
                      <a
                        href="https://bscscan.com/address/0xcf0990170A60DA34fFCffa14eAD4a3De27D0F4CE#code"
                        style={{
                          textDecoration: "none",
                          color: "#00E3C9",
                          lineBreak: "anywhere",
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        0xcf0990170A60DA34fFCffa14eAD4a3De27D0F4CE
                      </a>
                    </span>
                  </div>
                </div>
                <div className="item-column">
                  <div
                    className="column-title"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    Allocation:{" "}
                    <span className="amount_color">20-25,000 BUSD</span>
                    <br />{" "}
                  </div>
                  <div className="column-title"> Lock-up rules </div>
                  <div className="column-value">
                    <span style={{ lineHeight: "1.5px" }}>
                      {/*Vesting time and rules:*/}
                      Public Round - 20% unlocked at TGE. The rest 80% will be
                      unlocked monthly in the next 4 months.
                    </span>
                  </div>
                </div>

                <div className="item-column">
                  <div className="column-title"> Project Introduction </div>
                  <div className="column-value">
                    BamStarter is a Decentralized Finance (DeFi) Yield Optimizer
                    project, that allows its users to make more crypto with
                    crypto. DeFi applications are unique in the sense that they
                    are permission less and trustless, meaning that anyone with
                    a supported wallet can interact with them without the need
                    for a trusted middlemen. BamStarter caters its users by making
                    it easy to get a yield on their crypto capital in a safe and
                    decentralized manner. Through a set of smart contracts and
                    several investment strategies, BamStarter automatically
                    maximizes the user rewards from various liquidity pools
                    (LPs), automated market making (AMM) projects, and other
                    yield farming opportunities in the DeFi ecosystem. This
                    provides a huge advantage over attempting to do this
                    manually yourself. BamStarter up those yields!
                    <br />
                    <br />
                    Token information
                    <br />
                    Type: BEP20
                    <br />
                    Ticker: BTCBAM
                    <br />
                    Total supply: 10,000,000 (fixed)
                    <br />
                    Initial circulating supply: 550,000 BTCBAM
                    <br />
                    Initial Circulating market cap: $412,500
                    <br />
                    Fully diluted market cap: $7,500,000
                    <br />
                    <br />
                    <span className="text_white">TOKENOMICS</span>
                    <br />
                    Seed Round: $0.25 - 10% unlocked at TGE. The rest 90% will
                    be unlocked monthly in the next 9 months.
                    <br />
                    Private Round: $0.50 - 10% unlocked at TGE. The rest 90%
                    will be unlocked monthly in the next 6 months.
                    <br />
                    Public Round: $0.75 - 20% unlocked at TGE. The rest 80% will
                    be unlocked monthly in the next 4 months.
                    <br />
                    <br />
                    <span className="text_white">DEX Listing:</span> Pancakeswap
                    <br />
                    <span className="text_white">CEX Listing:</span> BitMart,
                    LBANK
                    <br />
                    <br />
                  </div>
                </div>
                <div className="harvestDesc"></div>
              </div>
              <div className="ido-container-right">
                <div className="left">
                  <img src={coinLogo} alt="" className="logo" />
                  <div>
                    <div className="ido-tags">
                      <div
                        className="status"
                        style={{ backgroundColor: "rgb(177, 33, 33)" }}
                      >
                        <div className="perch_detail moving_image">
                          <div className="perch_center_detail"></div>
                        </div>
                        <span>Ended</span>
                      </div>
                      <div className="chain-info">
                        <img src="" alt="" />
                        <div className="chain-name"> BSC </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="top-div">
                    <div className="amount-container">
                      <div className="amount color3">
                        <div className="amount-title">Time</div>
                        <div className="amount-value">
                          <div className="start-time time">
                            <div className="perch">
                              <div className="perch-center"></div>
                            </div>
                            2023.07.14 06:00 AM UTC
                          </div>
                          <div className="end-time time">
                            2023.04.12 09:00 AM UTC
                          </div>
                        </div>

                        <div
                          className="amount-title"
                          style={{ marginTop: "25px" }}
                        >
                          Progress
                        </div>
                        <div className="amount-value">
                          <div className="progress-desc">
                            <span> 500000 BUSD</span>
                            <span> 500,000 BUSD</span>
                          </div>
                          <div className="progress-plan progress-status2">
                            <div
                              role="progressbar"
                              aria-valuenow="0"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              className="el-progress el-progress--line is-success el-progress--without-text"
                            >
                              <div className="el-progress-bar">
                                <div
                                  className="el-progress-bar__outer"
                                  // style="height: 12px;"
                                >
                                  <div
                                    className="el-progress-bar__inner"
                                    style={{ width: innerWidth + "%" }}
                                    // style="width: 100%; background-color: rgb(51,51,75);"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <p className="ido-time" style={{ display: "none" }}>
                              <span> 00 Days 20:34:25</span>
                            </p>
                          </div>
                        </div>
                        {/* <div className="amount-title" style={{marginTop:'25px'}}>Time to Live</div>
                        <div className="amount-value">
                          <div className="start-time time ttl">
                          <Countdown date={Date.parse(date)} />
                          </div>
                        </div> */}
                      </div>
                      <div
                        className="amount-title"
                        style={{ marginTop: "25px" }}
                      >
                        My subscription amount
                        <div className="amount-value">{amount}</div>
                      </div>
                      <div
                        className="amount-title"
                        style={{ marginTop: "25px" }}
                      >
                        Available BTCBAM
                        <div className="amount-value">{balance}</div>
                      </div>
                      {status ? (
                        <>
                          <div
                            className="amount-title"
                            style={{ marginTop: "25px" }}
                          >
                            BUSD
                          </div>
                          <div className="ct1-input">
                            <input
                              type="text"
                              placeholder="Enter deposit amount"
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                            />
                            <span
                              onClick={handleAccountBalance}
                              className="ct1-max"
                            >
                              {" "}
                              MAX
                            </span>
                          </div>
                          {/* <div className="approve" onClick={handleDeposit}> */}
                          <div
                            className="approve"
                            style={{ background: "rgb(255, 255, 255, 0.1)" }}
                          >
                            Deposit
                          </div>
                          {/* <div className="claim_box"> */}
                          {/* <div className="claim" onClick={handleInitialClaim}> */}
                          {/* <div className="claim" style={{background:"rgb(255, 255, 255, 0.1)"}}> */}
                          {/* TGE Claim */}
                          {/* </div> */}
                          {/* <div className="claim" onClick={handleClaim}> */}
                          {/* <div className="claim" style={{background:"rgb(255, 255, 255, 0.1)"}}> */}
                          {/* <div className="approve" onClick={handleDeposit}> */}
                          {/* <div className="approve" style={{background:"rgb(255, 255, 255, 0.1)"}}> */}
                          <div className="approve" onClick={handleClaim}>
                            Claim
                          </div>
                          {/* </div> */}

                          <div className="approve" onClick={handleInitialClaim}>
                            TGE claim
                          </div>
                        </>
                      ) : (
                        <div className="approve" onClick={handleApprove}>
                          {/* <div className="approve disabled" > */}
                          Approve
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Public;
